import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { errorResponse, responseMessage } from '../../libs/app'
import { AuthContext } from '../../Contexts/AuthContext'
import ModalBox from '../../Components/ModalBox'
import DeleteModal from '../../Components/DeleteModal'
import { copyLink } from '../../helpers'
import IconWithTooltip from '../../Components/IconWithTooltip'
import { formatCurrency } from 'wearslot-dev-utils'


const PaymentLinks = () => {

    document.title = 'Payment Links - Instantly collect payments | Taojaa - Store Manager App';

    const { subscription, store, recordCounts, getSubscription, handleUpgradeModalShow } = useContext(AuthContext)

    const [saving, setSaving] = useState(false);
    const [modal, setModal] = useState(false);
    const [copied, setCopied] = useState(false);
    const [paymentLinks, setPaymentLinks] = useState([]);
    const [paymentLink, setPaymentLink] = useState({
        name: '',
        amount: 0,
        currency: store?.wallet.currency.abbr,
        order_no: '',
        status: 1
    });
    const [selectedForDelete, setSelectedForDelete] = useState(null);
    const availableCurrencies = [
        { name: 'Nigeria Naira', abbr: 'NGN' },
        { name: 'United States Dollars', abbr: 'USD' },
        { name: 'Great Britain Pounds', abbr: 'GBP' },
        { name: 'Rwandan Franc', abbr: 'RWF' }
    ];

    const statuses = [
        { id: 1, name: "Active" },
        { id: 0, name: "Deactivated" }
    ];

    useEffect(() => {
        if (store?.store_type === 'live' && (subscription?.expired || subscription === null)) {
            window.location = '/dashboard/access/choose/plan';
        }

        getPaymentLinks();
    }, [store, subscription]);

    async function getPaymentLinks() {
        try {
            const response = await axios.get(`/payments/links`);
            if (response.data.success) {
                setPaymentLinks(response.data.data);
                getSubscription();
            }
        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    async function savePaymentLink() {

        if (store.store_type === 'live' && subscription.settings.maxPaymentLinks !== 'unlimited' && paymentLink.id === undefined) {
            if ((Number(recordCounts.payment_links) + 1) > subscription.settings.maxPaymentLinks) {
                return handleUpgradeModalShow(
                    'Upgrade Your Plan',
                    'You have reached the maxiumun number of links allowed for your current plan, kindly upgrade now to create more links.'
                )
            }
        }

        try {
            setSaving(true)

            var response;

            if (paymentLink.id !== undefined) {
                response = await axios.put('/payments/update/link', paymentLink);
            } else {
                response = await axios.post('/payments/create/link', paymentLink);
            }

            if (response.data.success) {
                responseMessage(response.data.message, 'success')
                getPaymentLinks()
            }

            closeModal();

        } catch (error) {

            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }

        setSaving(false)
    }

    async function deletePaymentLink() {

        try {
            if (selectedForDelete === null) {
                return responseMessage("Something went wrong, invalid action attempted", "error");
            }

            const response = await axios.delete(`/payments/delete/link/${selectedForDelete}`);

            if (response.data.success) {
                responseMessage(response.data.message, "success");
                getPaymentLinks();

                closeDeleteModal()
            }

        } catch (error) {
            if (error.response)
                errorResponse(error.response);
            else
                responseMessage("Something went wrong.", "error");
        }
    }

    const editOption = (option) => {
        setPaymentLink(option);
        openModal()
    }

    const handlePaymentDetailChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setPaymentLink({ ...paymentLink, [name]: value });
    }

    const openModal = () => {
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
        setPaymentLink({
            name: '',
            currency: '',
            amount: 0,
            order_no: '',
            status: 1,
        })
    }

    const closeDeleteModal = () => {
        setSelectedForDelete(null)
    }

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">


                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Payments Links</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="#">Dashboard</a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="#">Payments</a>
                                        </li>
                                        <li className="breadcrumb-item active">Links</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row justify-content-center">
                        <div className="col-xxl-9">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Payment Links</h4>
                                </div>
                                <div className="card-body">
                                    <div className='d-flex justify-content-between'>
                                        <div className='bank-details'>
                                            <p className="text-muted">Create payment links to collect payment instantly in any currency.</p>

                                        </div>
                                        <div className=''>
                                            <button type="button" className="btn btn-primary btn-sm" onClick={openModal}>
                                                <i className="ri-add-circle-line"></i> <span className='desktop'>Create Link</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='table-responsive'>
                                        {paymentLinks.length > 0 && (
                                            <table
                                                className="table align-middle"
                                                id="paymentLinksTable"
                                            >
                                                <thead className="table-light text-muted">
                                                    <tr className="text-uppercase">
                                                        <th scope="col" style={{ width: "50" }}>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="checkAll"
                                                                    value="option"
                                                                />
                                                            </div>
                                                        </th>

                                                        <th className="sort" data-sort="name">
                                                            Name
                                                        </th>
                                                        <th className="sort" data-sort="amount">
                                                            Amount
                                                        </th>
                                                        <th className="sort" data-sort="status">
                                                            Status
                                                        </th>
                                                        <th className="sort" nowrap="nowrap" data-sort="order_no">
                                                            Order No
                                                        </th>
                                                        <th className="sort" data-sort="action">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {paymentLinks.map((link, index) => (
                                                        <tr key={index}>
                                                            <th scope="row">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="checkAll"
                                                                        value="link1"
                                                                    />
                                                                </div>
                                                            </th>
                                                            <td className="name">{link.name}</td>
                                                            <td className="amount">{link.currency}{formatCurrency(link.amount)}</td>
                                                            <td className="status">
                                                                {link.status == 1 ? <span className="badge badge-soft-success text-uppercase">Active</span>
                                                                    : <span className="badge badge-soft-danger text-uppercase">Deactivated</span>}
                                                            </td>
                                                            <td className="order_no">{link.order_no || ''}</td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li
                                                                        className="list-inline-item"
                                                                        title="Copy Link"
                                                                    >
                                                                        <IconWithTooltip message={copied ? 'Link copied!' : "Copy payment link"}>
                                                                            <button
                                                                                type="button"
                                                                                className="btn-sm btn btn-dark d-flex align-items-center gap-1"
                                                                                onClick={() => { copyLink(`https://pay.taojaa.com/payment/${link.link_id}`, setCopied) }}
                                                                            >
                                                                                <i className="ri-link fs-16"></i> Copy
                                                                            </button>
                                                                        </IconWithTooltip>
                                                                    </li>
                                                                    <li
                                                                        className="list-inline-item edit"
                                                                        title="Edit"
                                                                    >
                                                                        <a
                                                                            href="#paymentLink"
                                                                            className="text-primary d-inline-block edit-item-btn"
                                                                            onClick={() =>
                                                                                editOption(link)
                                                                            }
                                                                        >
                                                                            <i className="ri-pencil-fill fs-16"></i>
                                                                        </a>
                                                                    </li>
                                                                    <li
                                                                        className="list-inline-item"
                                                                        title="Remove"
                                                                    >
                                                                        <a
                                                                            className="text-danger d-inline-block remove-item-btn"
                                                                            href="#deletePaymentLink"
                                                                            onClick={() =>
                                                                                setSelectedForDelete(link.id)
                                                                            }
                                                                        >
                                                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )
                                        }
                                    </div>
                                    <div className="live-preview">
                                        <div>
                                            <ModalBox
                                                show={modal}
                                                handleClose={closeModal}
                                                title={paymentLink && paymentLink.id !== undefined ? 'Edit Payment Link' : 'Create Payment Link'}
                                                closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
                                                saveBtn={
                                                    (<button
                                                        type="button"
                                                        disabled={saving}
                                                        className={`btn btn-primary ${saving ? 'disabled' : ''}`}
                                                        onClick={() => savePaymentLink()}>
                                                        {saving
                                                            ? <>
                                                                <div className="spinner-border spinner-border-sm text-white" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div> Saving...
                                                            </> : 'Save'}
                                                    </button>)}
                                            >
                                                <form action="" method="POST" className="form" id="paymentLinkForm" encType="multipart/form-data">
                                                    <div className="card-body">
                                                        <div className="form-group row mb-3">
                                                            <label className="col-xl-12 col-lg-12 col-form-label text-right">Name</label>
                                                            <div className="col-lg-12 col-xl-12">
                                                                <input type="text" className="form-control form-control-solid" name="name"
                                                                    value={paymentLink.name} placeholder="Enter payment name e.g Payment for Order 10xxx"
                                                                    onChange={(e) => handlePaymentDetailChange(e)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row mb-3">
                                                            <div className="input-group">
                                                                <div className='col-3'>
                                                                    <label className="col-xl-12 col-lg-12 col-form-label text-right">
                                                                        Currency
                                                                        <IconWithTooltip message={'Kindly note that currency cannot be edited once link has been created or saved.'}>
                                                                            <small className='text-danger'><i className='ri-information-line'></i></small>
                                                                        </IconWithTooltip>
                                                                    </label>
                                                                    <div className="col-lg-12 col-xl-12">
                                                                        <select className="form-control form-control-solid" name="currency"
                                                                            onChange={(e) => handlePaymentDetailChange(e)} disabled={(paymentLink && paymentLink.id)}>
                                                                            {availableCurrencies.map((currency, index) => <option key={index} value={currency.abbr} selected={(currency.abbr === paymentLink.currency)}>{currency.abbr}</option>)}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='col-9'>
                                                                    <label className="col-xl-12 col-lg-12 col-form-label text-right">Amount</label>
                                                                    <div className="col-lg-12 col-xl-12">
                                                                        <input className="form-control form-control-solid" name="amount" type="number" value={paymentLink.amount}
                                                                            onChange={(e) => handlePaymentDetailChange(e)} placeholder="Enter Amount" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row mb-3">
                                                            <label className="col-xl-12 col-lg-12 col-form-label text-right">Order No. (optional)</label>
                                                            <div className="col-lg-12 col-xl-12">
                                                                <input type="number" className="form-control form-control-solid" name="order_id"
                                                                    value={paymentLink.order_no} placeholder="Enter order Number"
                                                                    onChange={(e) => handlePaymentDetailChange(e)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row mb-3">
                                                            <label className="col-xl-12 col-lg-12 col-form-label text-right">Status</label>
                                                            <div className="col-lg-12 col-xl-12">
                                                                <select
                                                                    name="status"
                                                                    className="form-control"
                                                                    value={paymentLink.status}
                                                                    onChange={(e) => handlePaymentDetailChange(e)}
                                                                >
                                                                    {statuses.map(({ id, name }) => {
                                                                        return (
                                                                            <option key={id} selected={paymentLink.status == id ? true : false} value={id}>{name}</option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </ModalBox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <DeleteModal
                    show={selectedForDelete ? true : false}
                    title={'Delete confirmation'}
                    message={'Are you sure you want to delete this payment link?'}
                    handleDelete={deletePaymentLink}
                    handleClose={closeDeleteModal}
                />
            </div>
            <Outlet />
        </>
    )
}

export default PaymentLinks